<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.resource.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <!--<CButton color="primary" v-on:click="createTableRow" v-if='checkPermission("create-resource")' style="margin-right: 10px;">{{ $lang.buttons.general.crud.create }}</CButton>-->
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Resource',self.userId)"
                  >
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <BeforeFilter
                    :filterShow="toggleFilterValue"
                    @getFilterToggle="switchFilter"
                ></BeforeFilter>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <transition name="fade">
            <CRow v-if="toggleFilterValue">
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.resource.form.firstname
                    }}</label>
                  <input
                      type="text"
                      v-model="resource.firstname"
                      class="form-control"
                  />
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.resource.form.lastname
                    }}</label>
                  <input
                      type="text"
                      v-model="resource.lastname"
                      class="form-control"
                  />
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.resource.form.nickname
                    }}</label>
                  <input
                      type="text"
                      v-model="resource.nickname"
                      class="form-control"
                  />
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.resource.form.experience }}
                  </label>
                  <v-select
                      :options="experienceOptions"
                      v-model="resource.experience"
                  ></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.resource.form.avaibility }}
                  </label>
                  <v-select
                      :options="availabilityOptions"
                      v-model="resource.avaibility"
                  ></v-select>
                </div>
              </CCol>
              <!--              <CCol sm="3">-->
              <!--                <div class="form-group">-->
              <!--                  <label class="form__label">{{-->
              <!--                    $lang.resource.form.currency-->
              <!--                  }}</label>-->
              <!--                  <v-select-->
              <!--                    :options="currencyOptions"-->
              <!--                    v-model="resource.currency"-->
              <!--                  ></v-select>-->
              <!--                </div>-->
              <!--              </CCol>-->
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.resource.form.fromPricePerHours }}
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.resource.form.fromPricePerHours"
                      class="form-control"
                      v-model="resource.fromPricePerHours"
                  />
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.resource.form.toPricePerHours }}
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.resource.form.toPricePerHours"
                      class="form-control"
                      v-model="resource.toPricePerHours"
                  />
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.resource.form.fromPricePerMonth }}
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.resource.form.fromPricePerMonth"
                      class="form-control"
                      v-model="resource.fromPricePerMonth"
                  />
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.resource.form.toPricePerMonth
                    }}</label>
                  <input
                      type="text"
                      :placeholder="$lang.resource.form.toPricePerMonth"
                      class="form-control"
                      v-model="resource.toPricePerMonth"
                  />
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label">{{
                      $lang.resource.form.skills
                    }}</label>
                  <v-select
                      multiple
                      :options="skillsOptions"
                      v-model="resource.skills"
                  ></v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.resource.form.skillOthers }}
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.resource.form.skillOthers"
                      class="form-control"
                      v-model="resource.skillOthers"
                  />
                </div>
              </CCol>
              <CCol md="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.resource.form.languages
                    }}</label>
                  <v-select
                      multiple
                      :options="languageOptions"
                      v-model="resource.languages"
                  ></v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.resource.form.country
                    }}</label>
                  <v-select
                      :options="countryOptions"
                      v-model="resource.country"
                  >
                  </v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.resource.form.remoteCountry
                    }}</label>
                  <v-select
                      :options="countryOptions"
                      v-model="resource.remoteCountry"
                      @option:selected="loadState"
                  >
                  </v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.resource.form.remoteState
                    }}</label>
                  <v-select
                      :options="StateOptions"
                      v-model="resource.remoteState"
                      @option:selected="loadCity"
                  ></v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.resource.form.remoteCity
                    }}</label>
                  <v-select
                      :options="cityOptions"
                      v-model="resource.remoteCity"
                  >
                  </v-select>
                </div>
              </CCol>
              <CCol sm="3" v-show="!iAmUser">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.resource.form.user
                    }}</label>
                  <v-select
                      :options="userOptions"
                      v-model="resource.user"
                  ></v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group" style="margin-top: 30px">
                  <CButton
                      type="submit"
                      size="sm"
                      color="primary"
                      @click="applyFilter()"
                      v-c-tooltip="$lang.common.filter.button.filter"
                  >
                    {{ $lang.common.filter.button.filter }}
                  </CButton>
                  &nbsp;
                  <CButton
                      color="danger"
                      size="sm"
                      @click="resetFilter()"
                      v-c-tooltip="$lang.common.filter.button.cancel"
                  >
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>

          <CAlert
              :color="messageColor"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >

          <v-server-table
              :columns="columns"
              :url="listUrl"
              :options="options"
              ref="myTable"
              @loaded="exports(self, 'Excel', module, 'Resource',self.userId)"
          >
            <template #title="data">
              <span :title="data.row.title">{{
                  trimfunction(data.row.title, 20)
                }}</span>
            </template>
            <template #firstName="data">
              <span :title="data.row.firstName">{{
                  trimfunction(data.row.firstName, 20)
                }}</span>
            </template>
            <template #lastName="data">
              <span :title="data.row.lastName">{{
                  trimfunction(data.row.lastName, 20)
                }}</span>
            </template>
            <template #nickname="data">
              <span :title="data.row.nickname">{{
                  trimfunction(data.row.nickname, 20)
                }}</span>
            </template>
            <template #userWithCompany="data">
              <Avatar
                  :image="data.row.avatar"
                  :defaultImage="data.row.avatar"
                  :content="data.row.userWithCompany"
                  :length="20"
              />
            </template>
            <template #actions="data">
              <CButton
                  color="primary"
                  v-c-tooltip="$lang.buttons.general.crud.view"
                  v-on:click="viewResponseTableRow(data.row.id, data.row.userId)"
                  v-if="checkPermission('list-resource')"
              >
                <i class="fas fa-eye"></i>
              </CButton>
              <!--              <CButton color="success" v-c-tooltip="$lang.buttons.general.crud.edit"-->
              <!--                       v-on:click="editTableRow(data.row.id)" v-if='checkPermission("edit-resource")'>-->
              <!--                <i class="fas fa-pencil-alt"></i>-->
              <!--              </CButton>-->
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.general.crud.delete"
                  v-on:click="deletePopup(data.row.id)"
                  v-if="checkPermission('delete-resource')"
              >
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  resource,
  resourcetype,
  experience,
  availability,
  currencytype,
  users,
  skills,
  language,
  country,
  state,
  city,
} from "/src/store/url.js";

import {Mixin} from "/src/mixins/index.js";

import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import BeforeFilter from "/src/views/BeforeFilterOpen";
import VueJsonToCsv from "vue-json-to-csv";
import Avatar from "/src/components/Avatar";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "ResourceReport",
  mixins: [Mixin],
  components: {
    BeforeFilter,
    VueJsonToCsv,
    Avatar,
  },
  data() {
    return {
      iAmUser: "",
      messageColor: "success",
      userId: "",
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      range: [0, 1000000],
      number: 1,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      resourceTypeOptions: [],
      experienceOptions: [],
      availabilityOptions: [],
      languageOptions: [],
      currencyOptions: [],
      durationOptions: [],
      priorityOptions: [],
      workTypeOptions: [],
      skillsOptions: [],
      selectedSkills: [],
      userOptions: [],
      remoteWorkOptions: [],
      profileVerifiedOptions: [],
      countryOptions: [],
      StateOptions: [],
      cityOptions: [],
      activePage: 1,
      module: resource,
      moduleCurrency: currencytype,
      moduleSkills: skills,
      moduleresourceType: resourcetype,
      moduleexperience: experience,
      moduleavailability: availability,
      modulelanguage: language,
      modulecountry: country,
      moduleCountry: country,
      moduleState: state,
      moduleCity: city,
      moduleUser: users,
      resource: {
        name: "",
        budget: "",
        isActive: "",
        remoteCountry: "",
        remoteState: "",
        remoteCity: "",
      },
      columns: ["userWithCompany", "title", "firstName", "updatedAt", "actions"], //'status'
      data: [],
      options: {
        headings: {
          userWithCompany: this.$lang.common.form.userName,
          title: this.$lang.common.form.title,
          firstName: this.$lang.resource.table.firstName,
          // lastName: this.$lang.resource.table.lastName,
          // nickname: this.$lang.resource.table.nickname,
          updatedAt: this.$lang.common.table.updatedAt,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["company", "nickname"],
        sortable: ["title", "company", "nickname"],
        filterable: ["title", "company", "nickname"],
      },
    };
  },
  created() {
    let self = this;
    self.listUrl = "/resource/list/";
    self.userId = this.$route.params.user_id;
    if (self.userId) {
      self.listUrl = this.listUrl + self.userId;
    } else {
      self.listUrl = this.listUrl;
    }
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;

    self.iAmUser = localStorage.getItem("iAmUser");

    // axios.get(this.listUrlApi(this.moduleCurrency)).then((response) => {
    //   response.data.data.map(function (value, key) {
    //     self.currencyOptions.push({ value: value.id, label: value.title });
    //   });
    // });
    axios.get(this.listUrlApi(this.moduleexperience)).then((response) => {
      response.data.data.map(function (value, key) {
        self.experienceOptions.push({value: value.id, label: value.title});
      });
    });
    // axios.get(this.listUrlApi(this.moduleresourceType)).then((response) => {
    //   response.data.data.map(function (value, key) {
    //     self.resourceTypeOptions.push({ value: value.id, label: value.title });
    //   });
    // });
    axios.get(this.listUrlApi(this.moduleavailability)).then((response) => {
      response.data.data.map(function (value, key) {
        self.availabilityOptions.push({value: value.id, label: value.title});
      });
    });
    axios.get(this.listUrlApi(this.moduleSkills)).then((response) => {
      response.data.data.map(function (value, key) {
        self.skillsOptions.push({value: value.id, label: value.title});
      });
    });
    axios.get(this.listUrlApi(this.moduleUser)).then((response) => {
      response.data.data.map(function (value, key) {
        self.userOptions.push({value: value.id, label: value.name});
      });
    });
    // axios.get(this.listUrlApi(this.moduleCurrency)).then((response) => {
    //   response.data.data.map(function (value, key) {
    //     self.currencyOptions.push({ value: value.id, label: value.title });
    //   });
    // });
    axios.get(this.listUrlApi(this.modulelanguage)).then((response) => {
      response.data.data.map(function (value, key) {
        self.languageOptions.push({value: value.id, label: value.title});
      });
    });
    axios.get(this.listUrlApi(this.modulecountry)).then((response) => {
      response.data.data.map(function (value, key) {
        self.countryOptions.push({value: value.id, label: value.name});
      });
    });
  },
  methods: {
    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },

    loadState() {
      let self = this;
      const postData = {countryId: self.resource.remoteCountry.value};
      self.resource.remoteState = {};
      self.resource.remoteCity = {};
      axios
          .post(this.listUrlApi(this.moduleState), postData)
          .then((response) => {
            self.StateOptions = [];
            response.data.data.map(function (value, key) {
              self.StateOptions.push({value: value.id, label: value.name});
            });
          });
    },
    loadCity() {
      let self = this;
      const postData = {stateId: self.resource.remoteState.value};
      self.resource.remoteCity = {};
      axios
          .post(this.listUrlApi(this.moduleCity), postData)
          .then((response) => {
            self.cityOptions = [];
            response.data.data.map(function (value, key) {
              self.cityOptions.push({value: value.id, label: value.name});
            });
          });
    },

    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "success";
                  self.dismissCountDownS = 10;
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDownS = 10;
          });
        }
      });
    },
    createTableRow() {
      this.$router.push({name: "ResourceCreate"});
    },
    editTableRow(item) {
      this.$router.push({path: `resource/edit/${item}`});
    },
    viewReportTableRow(item) {
      this.$router.push({path: `resource/report/list/${item}`});
    },
    viewResponseTableRow(item, userId) {
      this.$router.push({path: `resource/view/${item}/${userId}`});
    },
    applyFilter() {
      let firstname = "",
          lastname = "",
          nickname = "",
          fromPricePerHours = "",
          toPricePerHours = "",
          fromPricePerMonth = "";
      let toPricePerMonth = "";
      let currency = "";
      let experience = "",
          avaibility = "",
          country = "";

      let getskills = [],
          getskillOthers = [],
          getLanguages = [];

      if (this.resource.skills != null) {
        this.resource.skills.map(function (value, key) {
          getskills[key] = value.value;
        });
      }
      if (this.resource.skillOthers != null) {
        this.resource.skillOthers.map(function (value, key) {
          getskillOthers[key] = value.value;
        });
      }
      if (this.resource.languages != null) {
        this.resource.languages.map(function (value, key) {
          getLanguages[key] = value.value;
        });
      }
      if (this.resource.firstname != null) {
        firstname = this.resource.firstname;
      }
      if (this.resource.lastname != null) {
        lastname = this.resource.lastname;
      }
      if (this.resource.nickname != null) {
        nickname = this.resource.nickname;
      }
      if (this.resource.fromPricePerHours != null) {
        fromPricePerHours = this.resource.fromPricePerHours;
      }
      if (this.resource.toPricePerHours != null) {
        toPricePerHours = this.resource.toPricePerHours;
      }
      if (this.resource.fromPricePerMonth != null) {
        fromPricePerMonth = this.resource.fromPricePerMonth;
      }
      if (this.resource.toPricePerMonth != null) {
        toPricePerMonth = this.resource.toPricePerMonth;
      }
      if (this.resource.currency != null) {
        currency = this.resource.currency.value;
      }
      if (this.resource.experience != null) {
        experience = this.resource.experience.value;
      }
      if (this.resource.avaibility != null) {
        avaibility = this.resource.avaibility.value;
      }
      if (this.resource.country != null) {
        country = this.resource.country.value;
      }

      const reqObj = {
        firstName: firstname,
        lastName: lastname,
        nickname: nickname,
        experienceId: experience,
        availabilityId: avaibility,
        currencyId: currency,
        fromPricePerHours: fromPricePerHours,
        toPricePerHours: toPricePerHours,
        fromPricePerMonth: fromPricePerMonth,
        toPricePerMonth: toPricePerMonth,
        skills: getskills,
        otherSkills: getskillOthers,
        languageId: getLanguages,
        countryId: country,
      };
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },

    resetFilter() {
      this.resource.firstname = {};
      this.resource.lastname = {};
      this.resource.nickname = {};
      this.resource.fromPricePerHours = {};
      this.resource.toPricePerHours = {};
      this.resource.fromPricePerMonth = {};
      this.resource.toPricePerMonth = {};
      this.resource.currency = {};
      this.resource.skills = {};
      this.resource.experience = {};
      this.resource.avaibility = {};
      this.resource.skillOthers = {};
      this.resource.languages = {};
      this.resource.country = {};
      this.resource.remoteWork = {};
      this.resource.remoteAddress = {};
      this.resource.remoteCountry = {};
      this.resource.remoteState = {};
      this.resource.remoteCity = {};
      store.commit("showLoader", true);
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
  },
};
</script>
