<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.recruitments.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf"
                           v-on:click="exports(self,'pdf',module,'Recruitments',self.userId)">
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data"
                                :labels="json_label" :csv-title="json_title">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <BeforeFilter :filterShow="toggleFilterValue" @getFilterToggle="switchFilter"></BeforeFilter>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <transition name="fade">
            <CRow v-if="toggleFilterValue">
              <CCol sm="3">
                <div class="form-group" style="margin-top: 30px">
                  <CButton
                      type="submit"
                      size="sm"
                      color="primary"
                      @click="applyFilter()"
                      v-c-tooltip="$lang.common.filter.button.filter"
                  >
                    {{ $lang.common.filter.button.filter }}
                  </CButton> &nbsp;
                  <CButton color="danger" size="sm" @click="resetFilter()"
                           v-c-tooltip="$lang.common.filter.button.cancel">
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>
          <CAlert color="success" closeButton :show.sync="dismissCountDownS" fade>{{ alertMessage }}</CAlert>
          <v-server-table :columns="columns" :url="listUrl" :options="options" ref="myTable"
                          @loaded="exports(self,'Excel',module,'Recruitments',self.userId)">
            <template #description="data">
              <span :title="data.row.description">{{ trimfunction(data.row.description, 20) }}</span>
            </template>
            <template #minSalary="data">
              <span>{{ data.row.minSalary }}</span>
            </template>
            <template #maxSalary="data">
              <span>{{ data.row.maxSalary }}</span>
            </template>
            <template #experience="data">
              <span>{{ data.row.experience.name }}</span>
            </template>
            <template #firstName="data">
              <Avatar
                  :image="data.row.avatar"
                  :defaultImage="data.row.avatar"
                  :content="data.row.firstName"
                  :allowCompany="true"
                  :length="20"
              />
            </template>
            <template #actions="data">
              <!--v-if='checkPermission("view-post")'-->
              <CButton
                  color="primary"
                  v-c-tooltip="$lang.buttons.general.crud.view"
                  v-on:click="viewJob(data.row.id)"
                  v-if="checkPermission('list-job-opening')"
              >
                <i class="fas fa-eye"></i>
              </CButton>
              <CButton
                  color="primary"
                  v-c-tooltip="$lang.buttons.general.crud.candidates"
                  v-on:click="candidateList(data.row)"
                  v-if="checkPermission('list-job-opening')"
              >
                <i class="fas fa-user-friends"></i>
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.general.crud.delete"
                  v-on:click="deletePopup(data.row.id)"
                  v-if="checkPermission('list-job-opening')"
              >
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {Recruitments} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import BeforeFilter from "/src/views/BeforeFilterOpen";
import VueJsonToCsv from "vue-json-to-csv";
import Avatar from "/src/components/Avatar";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "UserRecruitments",
  mixins: [Mixin],
  components: {
    BeforeFilter,
    VueJsonToCsv,
    Avatar,
  },
  data() {
    return {
      jobTitle: "",
      selectedColumns: [],
      iAmUser: "",
      userId: "",
      listUrl: "",
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      range: [0, 1000000],
      number: 1,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      jobData: [],
      experience: [],
      requirement: [],
      worktype: [],
      visibilityOptions: [
        {
          value: 1,
          label: "Local",
        },
        {
          value: 2,
          label: "National",
        },
        {
          value: 3,
          label: "International",
        },
      ],
      durationOptions: [],
      priorityOptions: [],
      workTypeOptions: [],
      skillsOptions: [],
      selectedSkills: [],
      userOptions: [],
      remoteWorkOptions: [],
      profileVerifiedOptions: [],
      activePage: 1,
      module: Recruitments,
      recruitments: {
        title: '',
        registeredType: "",
        firstName: "",
        company: "",
      },
      columns: [
        "company",
        "firstName",
        "jobType",
        "title",
        "noOfOpenings",
        "experience",
        "minSalary",
        "maxSalary",
        "commissionType",
        "commissionPer",
        "createdAt",
        "updatedAt",
        "actions",
      ],
      data: [],
      options: {
        headings: {
          company: this.$lang.recruitments.table.company,
          firstName: this.$lang.recruitments.table.firstName,
          jobType: this.$lang.recruitments.table.jobtype,
          title: this.$lang.recruitments.table.title,
          noOfOpenings: this.$lang.recruitments.table.noOfOpenings,
          minSalary: this.$lang.recruitments.table.minSalary,
          maxSalary: this.$lang.recruitments.table.maxSalary,
          experience: this.$lang.recruitments.table.experience,
          commissionType: this.$lang.recruitments.table.commissionType,
          commissionPer: this.$lang.recruitments.table.commission,
          createdAt: this.$lang.recruitments.table.added,
          updatedAt: this.$lang.recruitments.table.updated,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: [],
        sortable: [
          "title",
        ],
        filterable: [
          "title",
        ],
      },
    };
  },
  created() {
    let self = this;
    self.listUrl = "/job-opening/my";
    self.userId = this.$route.params.user_id;
    if (!!self.userId) {
      self.listUrl = this.listUrl + "/" + this.$route.params.user_id;
    } else {
      self.listUrl = this.listUrl;
    }
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    store.commit("showLoader", false); // Loader Start
    localStorage.setItem("showAlert", "");
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },
    applyFilter() {
      let self = this;
      let postTypeId = "";
      let industry = "";
      let subIndustry = "";
      let resourceType = "";
      let duration = "";
      let priority = "";
      let profileVerified = "";
      let currency = "";
      let visibility = "";
      let remoteWork = "";
      let skills = "";
      let user = "";
      self.selectedSkills = [];
      if (this.recruitments.postTypeId != null) {
        postTypeId = this.recruitments.postTypeId.value;
      }
      if (this.recruitments.industry != null) {
        industry = this.recruitments.industry.value;
      }
      if (this.recruitments.subIndustry != null) {
        subIndustry = this.recruitments.subIndustry.value;
      }
      if (this.recruitments.resourceType != null) {
        resourceType = this.recruitments.resourceType.value;
      }
      if (this.recruitments.duration != null) {
        duration = this.recruitments.duration.value;
      }
      if (this.recruitments.priority != null) {
        priority = this.recruitments.priority.value;
      }
      if (this.recruitments.profileVerified != null) {
        profileVerified = this.recruitments.profileVerified.value;
      }
      if (this.recruitments.currency != null) {
        currency = this.recruitments.currency.value;
      }
      if (this.recruitments.visibility != null) {
        visibility = this.recruitments.visibility.value;
      }
      if (this.recruitments.remoteWork != null) {
        remoteWork = this.recruitments.remoteWork.value;
      }
      if (this.recruitments.user != null) {
        user = this.recruitments.user.value;
      }
      if (this.recruitments.skills != null) {
        skills = this.recruitments.skills;
        skills.map(function (value, key) {
          self.selectedSkills.push({
            value: value.value,
          });
        });
      }
      const reqObj = {
        postType: postTypeId,
        industry: industry,
        subIndustry: subIndustry,
        resourceType: resourceType,
        duration: duration,
        priority: priority,
        profileVerified: profileVerified,
        currency: currency,
        visibility: visibility,
        remoteWork: remoteWork,
        user: user,
        range: this.range,
      };
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },
    resetFilter() {
      this.recruitments.postTypeId = null;
      this.recruitments.industry = null;
      this.recruitments.subIndustry = null;
      this.recruitments.resourceType = null;
      this.recruitments.duration = null;
      this.recruitments.priority = null;
      this.recruitments.profileVerified = null;
      this.recruitments.currency = null;
      this.recruitments.visibility = null;
      this.recruitments.remoteWork = null;
      this.recruitments.user = null;
      this.range = [0, 1000000];
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios.delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.response.message;
                  self.dismissCountDownS = 10;
                }
              });
        }
      });
    },
    viewJob(id) {
      this.$router.push({path: `job-openings/view/${id}`});
    },
    candidateList(row) {
      localStorage.setItem('jobTitle', row.title);
      this.$router.push({path: `/job-openings/candidates/${row.id}`});
    },
  },
};
</script>
