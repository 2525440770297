<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>

          <strong>{{ userName }}</strong>

          <back-to-list></back-to-list>
        </CCardHeader>
        <CCardBody>
          <CTabs add-tab-classes="mt-1">
            <span @click="selected = 'Details'" v-show="isUser">
              <CTab>
                <template slot="title">
                  {{ $lang.profile.heading.user }}
                </template>
              </CTab>
            </span>

<!--            <span-->
<!--                @click="selected = 'post'"-->
<!--                v-if="checkPermission('list-post')"-->
<!--            >-->
<!--              <CTab>-->
<!--                <template slot="title">-->
<!--                  {{ $lang.profile.tab.posts }}-->
<!--                </template>-->
<!--              </CTab>-->
<!--            </span>-->

            <span
                @click="selected = 'Opportunity'"
            >
              <CTab active>
                <template slot="title">
                  {{ $lang.profile.tab.opportunity }}
                </template>
              </CTab>
            </span>

            <span
                @click="selected = 'businesstraveller'"
                v-if="checkPermission('list-business-traveller')"
            >
              <CTab>
                <template slot="title">
                  {{ $lang.profile.tab.bts }}
                </template>
              </CTab>
            </span>

            <span
                @click="selected = 'Resource'"
                v-if="checkPermission('list-resource')"
            >
              <CTab>
                <template slot="title">
                  {{ $lang.profile.tab.resources }}
                </template>
              </CTab>
            </span>

            <span
                @click="selected = 'UserRecruitments'"
                v-if="checkPermission('list-job-opening')"
            >
              <CTab>
                <template slot="title">
                  {{ $lang.profile.tab.recruitment }}
                </template>
              </CTab>
            </span>
            <span
                @click="selected = 'Candidates'"
                v-if="checkPermission('list-candidate')"
            >
              <CTab>
                <template slot="title">
                  {{ $lang.profile.tab.candidates }}
                </template>
              </CTab>
            </span>
            <span
                @click="selected = 'OtherCandidates'"
                v-if="checkPermission('list-candidate')"
            >
              <CTab>
                <template slot="title">
                  {{ $lang.profile.tab.otherCandidates }}
                </template>
              </CTab>
            </span>
          </CTabs>
          <component
              :is="selected"
              style="margin-top: 20px"
              v-bind:userId="currentId"
              @changeSelected="getTab($event)"
          ></component>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import store from "/src/store/store.js";
import {Mixin} from "/src/mixins/index.js";
import Details from "/src/views/profile/tab/Details.vue";
// import post from "/src/views/post/Post.vue";
import Opportunity from "/src/views/opportunity/Opportunity.vue";
import UserRecruitments from "/src/views/profile/tab/Recruitments.vue";
import Candidates from "/src/views/profile/tab/Candidates.vue";
import OtherCandidates from "./tab/OtherCandidates";
import businesstraveller from "/src/views/businessTraveller/BusinessTraveller.vue";
import Resource from "/src/views/profile/tab/Resources.vue";
import {profile} from "/src/store/url.js";
import BackToList from "../backToList.vue";
import {serverBus} from "/src/main";

export default {
  name: "AllEntitiesDetails",
  mixins: [Mixin],
  components: {
    // post,
    Opportunity,
    businesstraveller,
    Resource,
    Details,
    BackToList,
    UserRecruitments,
    Candidates,
    OtherCandidates
  },
  data() {
    return {
      selected: "Opportunity",
      isUser: "",
      currentId: "",
      userId: "",
      postActive: "active",
      resourceActive: "",
      opportunityActive: "",
      bTActive: "",
      module: profile,
      userName: '',
    };
  },
  mounted() {
    let self = this;
    self.currentId = this.$route.params.id;

    serverBus.$on("send_user_with_company_name", function (userName) {
      self.userName = userName;

    });

    localStorage.removeItem("activeTab");
    if (localStorage.getItem("activeTab") === "resource/") {
      self.resourceActive = "active";
      self.postActive = "";
      self.opportunityActive = "";
      self.btActive = "";
      this.getTab("Resource");
    }

    axios
        .get(this.viewUrlApi(this.module, this.$route.params.user_id))
        .then((response) => {
          // eslint-disable-next-line no-empty
          if (response.data) {
            let responseData = response.data;
            self.isUser = responseData.parentId;
            if (responseData.parentId) {
              self.selected = "Details";
            } else {
              self.selected = "Opportunity";
            }
            store.commit("showLoader", false); // Loader Off
          }
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });

    store.commit("showLoader", false); // Loader Start
  },
  methods: {
    cancel() {
      //this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/books'})
    },
    getTab(tab) {
      let self = this;
      self.selected = tab;
    },
  },
};
</script>
<style>
div .error {
  color: #ff0000;
}

.hasError label {
  color: red;
}
</style>

