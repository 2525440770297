<template>
  <CRow>
    <CModal
        :no-close-on-backdrop="true"
        :show.sync="largeModal"
        :title="
        submitType === 'Update'
          ? $lang.candidates.crud.view
          : $lang.candidates.crud.view
      "
        color="primary"
        size="lg"
    >
      <CAlert :show.sync="dismissCountDown" closeButton color="danger" fade>{{ err_msg }}
      </CAlert>
      <CForm novalidate @submit.prevent="onSubmit">
        <CCardBody>
          <CRow>
            <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.candidates.detail.otherCandidates.position"
                     :value="responseData.title"></MakeCol>
          </CRow>
          <CRow>
            <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.candidates.detail.cFirstName"
                     :value="responseData.firstName"></MakeCol>
            <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.candidates.detail.cLastName"
                     :value="responseData.lastName"></MakeCol>
          </CRow>
          <CRow>
            <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.candidates.detail.otherCandidates.email"
                     :value="responseData.email"></MakeCol>
            <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.candidates.detail.otherCandidates.mobile"
                     :value="responseData.mobileNo"></MakeCol>
          </CRow>

          <CRow>
            <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.candidates.detail.otherCandidates.currentCtc"
                     :value="responseData.currentCtc"></MakeCol>
            <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.candidates.detail.minSalary"
                     :value="responseData.minSalary"></MakeCol>
            <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.candidates.detail.maxSalary"
                     :value="responseData.maxSalary"></MakeCol>
          </CRow>
          <CRow>
            <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.candidates.detail.jobtype"
                     :value="responseData.jobType"></MakeCol>
            <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.candidates.detail.otherCandidates.qualification"
                     :value="responseData.qualification"></MakeCol>
          </CRow>
          <CRow>
            <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.candidates.detail.otherCandidates.experience"
                     :value="experience"></MakeCol>
            <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.candidates.detail.otherCandidates.availability"
                     :value="availability"></MakeCol>
            <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.candidates.detail.otherCandidates.worktype"
                     :value="worktype"></MakeCol>
          </CRow>
          <CRow>
            <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.candidates.detail.otherCandidates.country"
                     :value="country"></MakeCol>
            <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.candidates.detail.otherCandidates.state"
                     :value="state"></MakeCol>
            <MakeCol :lg="4" :md="4" :sm="6" :title="$lang.candidates.detail.otherCandidates.city"
                     :value="city"></MakeCol>
          </CRow>
          <CRow>
            <MakeCol :lg="4" :md="6" :sm="6" :title="$lang.candidates.detail.otherCandidates.skills"
                     :value="skills"></MakeCol>
          </CRow>
          <entity-time-stamp :createdAt="responseData.createdAt" :profilerId="responseData.userId"
                             :updatedAt="responseData.updatedAt"></entity-time-stamp>
        </CCardBody>
        <CCardFooter>
        </CCardFooter>
      </CForm>
      <template #footer style="display: none">
        <CButton color="danger" style="display: none" @click="darkModal = false"
        >Discard
        </CButton
        >
        <CButton
            color="success"
            style="display: none"
            @click="darkModal = false"
        >Accept
        </CButton
        >
      </template>
    </CModal>


    <CCol col="12">
      <CCard>

        <CCardHeader>
          <div class="row">
            <CCol class="align-self-center" sm="6">
              <strong>{{ $lang.candidates.title }}</strong>
            </CCol>
            <CCol class="align-self-center" sm="6">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton v-c-tooltip="$lang.buttons.export.pdf" color="danger"
                           v-on:click="exports(self,'pdf',module,'OtherCandidates',self.userId)">
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv v-c-tooltip="$lang.buttons.export.excel" :csv-title="json_title" :json-data="json_data"
                                :labels="json_label" class="btn btn-success">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <BeforeFilter :filterShow="toggleFilterValue" @getFilterToggle="switchFilter"></BeforeFilter>
              </div>
            </CCol>
          </div>
        </CCardHeader>

        <CCardBody>
          <transition name="fade">
            <CRow v-if="toggleFilterValue">

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.candidates.filter.experience }}</label>
                  <v-select v-model="filter.experienceList" :options="experienceList"></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.candidates.filter.jobType }}</label>
                  <v-select v-model="filter.jobTypeList" :options="jobTypeList"></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.candidates.filter.workType }}</label>
                  <v-select v-model="filter.workTypeList" :options="workTypeList"></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.candidates.filter.otherCandidates.availabilities }}</label>
                  <v-select v-model="filter.availabilityList" :options="availabilityList"></v-select>
                </div>
              </CCol>

             <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.candidates.filter.otherCandidates.position }}</label>
                  <input v-model="filter.title" class="form-control" type="text"/>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group" style="margin-top: 30px">
                  <CButton
                      v-c-tooltip="$lang.common.filter.button.filter"
                      color="primary"
                      size="sm"
                      type="submit"
                      @click="applyFilter()"
                  >
                    {{ $lang.common.filter.button.filter }}
                  </CButton> &nbsp;
                  <CButton v-c-tooltip="$lang.common.filter.button.cancel" color="danger" size="sm"
                           @click="resetFilter()">
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>

          <v-server-table ref="myTable" :columns="columns" :options="options"
                          :url="listUrl+`${this.$route.params.user_id}`"
                          @loading="loading=true"
                          @loaded="exports(self,'Excel',module,'OtherCandidates',self.userId)">
            <template #description="data">
              <span :title="data.row.description">{{ trimfunction(data.row.description, 20) }}</span>
            </template>
            <template #minSalary="data">
              <span>{{ data.row.minSalary }}</span>
            </template>
            <template #maxSalary="data">
              <span>{{ data.row.maxSalary }}</span>
            </template>
            <template #experience="data">
              <span>{{ data.row.experience.title }}</span>
            </template>
            <template #workType="data">
              <span>{{ data.row.worktype.title }}</span>
            </template>
            <template #actions="data">
              <!--v-if='checkPermission("view-post")'-->
              <CButton
                  v-if="checkPermission('list-candidate')"
                  v-c-tooltip="$lang.buttons.general.crud.view"
                  color="primary"
                  v-on:click="viewCandidate(data.row.id)"
              >
                <i class="fas fa-eye"></i>
              </CButton>
              <CButton
                  v-if="checkPermission('list-candidate')"
                  v-c-tooltip="$lang.buttons.general.crud.delete"
                  color="danger"
                  v-on:click="deletePopup(data.row.id)"
              >
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  otherCandidates
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import BeforeFilter from "/src/views/BeforeFilterOpen";
import VueJsonToCsv from "vue-json-to-csv";
import Avatar from "/src/components/Avatar";
import EntityTimeStamp from "../../../components/EntityTimeStamp";
import MakeCol from "../../../components/MakeCol";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "OtherCandidates",
  mixins: [Mixin],
  components: {
    EntityTimeStamp,
    BeforeFilter,
    VueJsonToCsv,
    Avatar,
    MakeCol
  },
  data() {
    return {
      selectedColumns: [],
      iAmUser: "",
      userId: "",
      listUrl: "/other-candidates/company/",
      list: [],
      experienceList: [],
      commissionTypeList: [],
      workTypeList: [],
      availabilityList: [],
      responseData: [],
      experience: "",
      availability: "",
      worktype: "",
      country: "",
      city: "",
      state: "",
      skills: [],
      currencyList: [],
      jobTypeList: [],
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      range: [0, 1000000],
      number: 1,
      err_msg: "",
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      largeModal: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      jobData: [],
      requirement: [],
      activePage: 1,
      module: otherCandidates,
      filter: {
        experienceList: "",
        jobTypelist: "",
        title: "",
        workTypeList: "",
        availabilityList: "",
      },
       columns: [
        "firstName",
        "lastName",
        "title",
        'jobType',
        "experience",
        "workType",
        "availability",
        'currentCtc',
        "minSalary",
        "maxSalary",
        "createdAt",
        "updatedAt",
        "actions",
      ],
      data: [],
      options: {
        headings: {
          company: this.$lang.candidates.table.company,
          firstName: this.$lang.candidates.table.otherCandidates.firstName,
          lastName: this.$lang.candidates.table.otherCandidates.lastName,
          title: this.$lang.candidates.table.otherCandidates.position,
          jobType: this.$lang.candidates.table.jobtype,
          currentCtc: this.$lang.candidates.table.otherCandidates.currentCtc,
          minSalary: this.$lang.candidates.table.minSalary,
          maxSalary: this.$lang.candidates.table.maxSalary,
          experience: this.$lang.candidates.table.experience,
          workType: this.$lang.candidates.table.workType,
          availability: this.$lang.candidates.table.otherCandidates.availability,
          createdAt: this.$lang.candidates.table.added,
          updatedAt: this.$lang.candidates.table.updated,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: [],
        sortable: [
          "title",
        ],
        filterable: [],
      },
    };
  },
  created() {
    let self = this;
    self.userId = this.$route.params.user_id;
    this.getList();
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    store.commit("showLoader", false);
    self.userId = this.$route.params.user_id;
    self.jobTypeList.push(
        {value: 'WFH', label: 'WFH'},
        {value: 'WFO', label: 'WFO'},
        {value: 'BOTH', label: 'BOTH'},
    );
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    getList() {
      let self = this;
      self.userList = "/other-candidates/company/"+this.$route.params.user_id;
      axios.get(self.userList)
          .then((response) => {
            // console.log(response.data);
            let responseData = response.data;
            responseData.data.map(function (value, key) {
              self.experienceList.push({value: value.experience.id, label: value.experience.title});
              self.workTypeList.push({value: value.worktype.id, label: value.worktype.title});
              self.availabilityList.push({value: value.availability.id, label: value.availability.title});
            });
            // console.log(self.experienceList);
            self.experienceList = this.removeDuplicateValues(self.experienceList);
            self.workTypeList = this.removeDuplicateValues(self.workTypeList);
            self.availabilityList = this.removeDuplicateValues(self.availabilityList);
            self.currencyList = this.removeDuplicateValues(self.currencyList);
          });
    },
    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },
    applyFilter() {
      let self = this;
      let reqObj = {
        companyId: this.$route.params.user_id,
        experienceId: self.filter.experienceList?.value,
        jobType: self.filter.jobTypeList?.value,
        workType: self.filter.workTypeList?.value,
        availability: self.filter.availabilityList?.value,
        title: (self.filter.title) ? self.filter.title : null,
      };
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },
    resetFilter() {
      this.$data.filter = [];
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();

    },
    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios.delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.response.message;
                  self.dismissCountDownS = 10;
                }
              });
        }
      });
    },
    viewCandidate(id) {
      let self = this;
      let skillList = [];
      axios
          .get(this.viewUrlApi(this.module, id), self.model)
          .then(function (response) {
            if (response) {
              self.responseData = response.data.data;
              self.experience = self.responseData?.experience?.title;
              self.availability = self.responseData?.availability?.title;
              self.worktype = self.responseData?.worktype?.title;
              self.country = self.responseData?.country?.name;
              self.city = self.responseData?.city?.name;
              self.state = self.responseData?.state?.name;
              self.responseData?.skills.map(function (element, index, array) {
                skillList.push(element.title);
              });
              self.skills = skillList.join(", ");
            } else {
              self.data = [];
            }
          });
      self.largeModal = true;
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
  },
};
</script>
